class App {

  constructor(){
    this.bindEvents();
  }

  bindEvents() {
    document.addEventListener('alpine:init', () => {
      console.log('🏔️ Alpine.js initialized');

      Alpine.data('utils', () => ({
        showDetails() {
          document.querySelector('header').scrollIntoView({ behavior: 'smooth' });
          document.querySelector('body').classList.add('has-details');
        },
        hideVideo() {
          document.querySelector('.lobster-detail').classList.remove('is-video');
          document.querySelector('.pager .page-first').classList.add('is-selected');
          document.querySelector('.pager .page-last').classList.remove('is-selected');
        },
        showVideo() {
          document.querySelector('.lobster-detail').classList.add('is-video');
          document.querySelector('.pager .page-first').classList.remove('is-selected');
          document.querySelector('.pager .page-last').classList.add('is-selected');
        }
      }));

      Alpine.data('overlay', () => ({
        overlay: null,
        init() {
          this.overlay = this.$el;
        },
        open() {
          this.overlay.classList.add('is-open');
        },
        close() {
          this.overlay.classList.remove('is-open');
        }
      }));

      Alpine.data('quantity', () => ({
        value: 1,
        increase() {
          this.value += this.value < 99 ? 1 : 0;
        },
        decrease() {
          this.value -= this.value > 1 ? 1 : 0;
        },
        update() {
          this.value = parseInt(this.$el.value);
        }
      }));

      Alpine.data('accordion', () => ({
        container: null,
        init() {
          this.container = this.$el;
        },
        toggle() {
          this.container.classList.toggle('is-open');
        }
      }));

      Alpine.data('tags', () => ({
        container: null,
        init() {
          this.container = this.$el;
          console.log(navigator.share);
          if (! navigator.share ) {
            this.$refs.share.classList.add('d-none');
          }
        },
        toggle() {
          const tag = this.$el.closest('.col-12, .col-6');
          if ( tag.classList.contains('col-12') ) {
            this.container.querySelectorAll('.col-6').forEach(col => {
              col.classList.remove('d-none');
            });
            tag.classList.remove('col-12');
            tag.classList.add('col-6');
            this.$refs.button.classList.add('disabled');
          } else {
            this.container.querySelectorAll('.col-6').forEach(col => {
              col.classList.add('d-none');
            });
            tag.classList.remove('d-none');
            tag.classList.remove('col-6');
            tag.classList.add('col-12');
            this.$refs.button.classList.remove('disabled');
          }
        },
        share() {
          try {
            const data = {
              title: "MDN",
              text: "Learn web development on MDN!",
              url: "https://developer.mozilla.org",
            };
            navigator.share(data);
          } catch(err) {
            console.log(err);
          }
        }
      }));

      Alpine.data('expander', () => ({
        container: null,
        init() {
          this.container = this.$el;
        },
        expand() {
          const anchor = this.$el;
          const articles = this.container.querySelectorAll('article');
          articles.forEach(article => {
            article.classList.remove('is-expanded');
          });
          anchor.closest('article').classList.add('is-expanded');
        }
      }));

      Alpine.data('menu', () => ({
        menu: null,
        init() {
          this.menu = document.querySelector('.menu-overlay');
        },
        open() {
          this.menu.classList.add('is-open');
        },
        close() {
          this.menu.classList.remove('is-open');
        },
      }));

      Alpine.data('oracle', () => ({
        overlay: null,
        init() {
          this.overlay = document.querySelector('.overlay-oracle');
          this.transition();
        },
        transition() {
          this.overlay.classList.add('is-open');
          this.overlay.style.opacity = 1;
          jQuery(this.overlay).animate({
            opacity: 0
          }, 1500, () => {
            this.overlay.classList.remove('is-open');
          });
        },
        dismiss() {
          document.querySelector('.wrapper-splash').classList.add('d-none');
          this.transition();
        },
        reading() {
          document.querySelector('.wrapper-question').classList.add('d-none');
          document.querySelector('.site-header').classList.add('is-fixed');
          document.querySelector('.wrapper-reading').classList.remove('d-none');
          this.transition();
        },
        showVideo() {
          document.querySelector('.lobster .box').classList.add('d-none');
          document.querySelector('.lobster .video-container').classList.remove('d-none');
          document.querySelector('.pager .page-first').classList.remove('is-selected');
          document.querySelector('.pager .page-last').classList.add('is-selected');
        },
        hideVideo() {
          document.querySelector('.lobster .box').classList.remove('d-none');
          document.querySelector('.lobster .video-container').classList.add('d-none');
          document.querySelector('.pager .page-first').classList.add('is-selected');
          document.querySelector('.pager .page-last').classList.remove('is-selected');
        }
      }));

      Alpine.data('faqs', () => ({
        container: null,
        init() {
          this.container = this.$el;
        },
        open() {
          const current = this.$el.closest('.faq');
          const faqs = this.container.querySelectorAll('.faq');
          if (current.classList.contains('is-open')) {
            current.classList.remove('is-open');
          } else {
            faqs.forEach(faq => {
              faq.classList.remove('is-open');
            });
            current.classList.add('is-open');
          }
        }
      }));

      Alpine.data('cycler', () => ({
        container: null,
        pos: 0,
        init() {
          this.container = document.querySelector('.lobster-cycler .lobster');
          if (this.container) {
            this.preload();
          }
        },
        preload() {
          this.container.classList.add('is-loading');
          let index = 0;
          let pending = window.Lobsters.length;
          window.Lobsters.forEach((lobster) => {
            const img = document.createElement('img');
            const id = index;
            img.onload = () => {
              window.Lobsters[id].assets = window.Lobsters[id].assets || {};
              window.Lobsters[id].assets.image = img;
              window.Lobsters[id].loaded = true;
              pending--;
              if (pending === 0) {
                this.cycle();
                this.container.classList.remove('is-loading');
              }
            };
            window.Lobsters[index].loaded = false;
            img.src = `${window.Url.siteUrl}/img/lobster/${lobster.image}`;
            index++;
          });
        },
        cycle() {
          const image = this.container.querySelector('img');
          const anchor = this.container.querySelector('a');
          image.src = window.Lobsters[this.pos].assets.image.src;
          anchor.href = `${window.Url.siteUrl}/mutation/${this.pos + 1}`;
          this.pos++;
          if (this.pos >= window.Lobsters.length) {
            this.pos = 0;
          }
          setTimeout(() => {
            this.cycle();
          }, 500);
        }
      }));
    });
  }
}

window.App = window.App || new App();
